/* #verification-page ion-img {
    height: 50px;
}

#verification-page ion-header {
    background-color: #5C4E92;
}

#verification-page ion-title {
    text-align: center;
}

#verification-page ion-content .bottom-content {
    position: fixed;
    bottom: 0;
    width: 100%;
}

#verification-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

#verification-page .image-jp {
    min-height: 200pt;
    padding: 50pt;
}

@media screen and (max-width:320px) {
    #verification-page .image-jp {
        padding: 34pt;
    }
}

@media screen and (max-height:320px) {
    #verification-page ion-content .bottom-content {
        position: relative;
        bottom: auto;
    }
} */

#verification-page ion-img {
    height: 50px;
}

#verification-page ion-header {
    background-color: #5C4E92;
}

#verification-page ion-title {
    text-align: center;
}

#verification-page .bottom-content {
    flex: 0 0 0%;
    margin: 0;
}

#verification-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#verification-page .image-jp {
    height: 100%;
    padding: 50pt;
}

#verification-page .fontStyling {
    font-size: medium;
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

#verification-page .resendCodeButton {
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: medium;
}

#verification-page .clearButton {
    margin: auto;
    margin-top: 0.5rem;
    font-size: small;
}

#verification-page .divBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.verification-input {
    margin: auto;
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.verification-input .ReactInputVerificationCode__item {
    position: relative;
    color: #262626;
    font-weight: 500;
  }

  .verification-input .ReactInputVerificationCode__item,
.verification-input .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.verification-input .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #ebebeb;
  transition: background-color 0.2s ease-out;
}

.verification-input .ReactInputVerificationCode__item.is-active:after {
  background-color: #5C4E92;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }