#add-parking-page ion-header {
    background-color: #5C4E92;
}

#add-parking-page ion-label {
    font-size: 13px;
}

#add-parking-page ion-title {
    text-align: center;
}

#add-parking-page .input-wrapper {
    border: 0;
}

#add-parking-page .pbtName {
    font-size: 14px;
    margin: 13px;
}

#add-parking-page .zoneName {
    font-size: 18px;
    margin: 0;
}

#add-parking-page .editPbtIcon {
    float: right;
    color: #5C4E92;
}

#add-parking-page .divBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

#add-parking-page .pbtLogo {
    width: 86px;
    height: auto;
}

@media only screen and (max-width: 640px) {
    #add-parking-page .pbtLogo {
        width: 20%;
    }
}

@media only screen and (min-width: 1024px) {
    #add-parking-page .pbtLogo {
        width: 86px;
    }
}