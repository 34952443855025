#home-page .jomparkingImage {
    height: 50px;
}

#home-page ion-header {
    background-color: #5C4E92;
}

#home-page ion-title {
    text-align: center;
}

#home-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#home-page .image-center .center ion-img {
    text-align: center;
    width: 100pt;
    margin: auto;
    margin-top: 25px;
}

#home-page .image-center .center ion-label {
    display: block;
    text-align: center;
    font-size: 10pt;
    margin-top: 10pt;
}

@media only screen and (min-width: 640px) {
    #home-page .image-center .center ion-img {
        width: 200pt;
    }
    #home-page .image-center .center ion-label {
        font-size: 14pt;
        margin-top: 14pt;
    }
}

@media only screen and (min-width: 1024px) {
    #home-page .image-center .center ion-img {
        width: 300pt;
    }
    #home-page .image-center .center ion-label {
        font-size: 24pt;
        margin-top: 24pt;
    }
}

#home-page .scrollableList {
    /* height: auto; */
    overflow-y: scroll;
}

#home-page .headerFontWeight {
    font-weight: bold;
}