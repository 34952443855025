#compound-page ion-header {
    background-color: #5C4E92;
}

#compound-page ion-title {
    text-align: center;
    color: white;
}

#compound-page ion-icon {
    color: white;
}

#compound-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#compound-page .image-center .center ion-img {
    text-align: center;
    width: 100pt;
}

#compound-page .image-center .center ion-label {
    display: block;
    text-align: center;
    font-size: 10pt;
    margin-top: 10pt;
}

@media only screen and (min-width: 640px) {
    #compound-page .image-center .center ion-img {
        width: 200pt;
    }
    #compound-page .image-center .center ion-label {
        font-size: 14pt;
        margin-top: 14pt;
    }
}

@media only screen and (min-width: 1024px) {
    #compound-page .image-center .center ion-img {
        width: 300pt;
    }
    #compound-page .image-center .center ion-label {
        font-size: 24pt;
        margin-top: 24pt;
    }
}