#view-receipt-page ion-header {
    background-color: #5C4E92;
}

#view-receipt-page ion-title {
    text-align: center;
    color: white;
}

#view-receipt-page ion-icon {
    color: white;
}

#view-receipt-page .noteAdjust {
    font-size: 10px;
}