ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
}

.cardMargin {
    margin: 0;
    margin-inline: auto !important;
}

.plateNumberAdjustment {
    font-size: large;
    margin: auto;
}

.descAdjustment {
    font-size: small;
    text-align: start;
    margin: auto;
}

.editRemoveIcon {
    float: right;
    color: #5C4E92;
}