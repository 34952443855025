#details-page ion-header {
    background-color: #5C4E92;
}

#details-page ion-title {
    text-align: center;
    color: white;
}

#details-page ion-icon {
    color: white;
}

#details-page .iconList {
    color: black;
}

#details-page .avatarImage {
    margin: 0 15px 0 auto;
}

#details-page hr {
    border-top: 3px solid #bbb;
}