#register-page ion-img {
    height: 50px;
}

#register-page ion-header {
    background-color: #5C4E92;
}

#register-page ion-title {
    text-align: center;
    background-color: #5C4E92;
}

#register-page ion-label {
    font-size: 13px;
}

#tnc-text {
    font-size: 12px;
}