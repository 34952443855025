.cardMargin {
    margin: 0;
    margin-inline: auto !important;
}

.titleAdjust {
    float: right;
    margin-top: 26px;
    margin-inline-end: 15px;
    background-color: #5C4E92;
    padding-inline-start: 6px;
    padding-inline-end: 6px;
    color: white;
}

.titleAdjustTopup {
    float: right;
    margin-top: 26px;
    margin-inline-end: 15px;
    background-color: #77ce44;
    padding-inline-start: 6px;
    padding-inline-end: 6px;
    color: white;
}

.titleAdjustMp {
    float: right;
    margin-top: 26px;
    margin-inline-end: 15px;
    background-color: #77ce44;
    padding-inline-start: 6px;
    padding-inline-end: 6px;
    color: white;
}

.titleAdjustCom {
    float: right;
    margin-top: 26px;
    margin-inline-end: 15px;
    background-color: #fb5e92;
    padding-inline-start: 6px;
    padding-inline-end: 6px;
    color: white;
}

.titleAdjustRef {
    float: right;
    margin-top: 26px;
    margin-inline-end: 15px;
    background-color: #f95e5d;
    padding-inline-start: 6px;
    padding-inline-end: 6px;
    color: white;
}

ion-label p small {
    color: white;
}