#parking-passes-page ion-img {
    height: 50px;
}

#parking-passes-page ion-header {
    background-color: #5C4E92;
}

#parking-passes-page ion-title {
    text-align: center;
    color: white;
}

#parking-passes-page ion-icon {
    color: white;
}

#parking-passes-page .bottom-content {
    flex: 0 0 0%;
    margin: 0;
}

#parking-passes-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#parking-passes-page .image-jp {
    height: 100%;
    padding: 50pt;
}