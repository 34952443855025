#login-page ion-img {
    height: 50px;
}

#login-page ion-header {
    background-color: #5C4E92;
}

#login-page ion-label {
    font-size: 13px;
}

#login-page .divBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}