#add-vehicle-page ion-img {
    height: 50px;
}

#add-vehicle-page ion-header {
    background-color: #5C4E92;
}

#add-vehicle-page ion-label {
    font-size: 13px;
}

#add-vehicle-page ion-title {
    text-align: center;
}

#add-vehicle-page .input-wrapper {
    border: 0;
}

#add-vehicle-page .pbtName {
    font-size: 14px;
    margin: 0;
}

#add-vehicle-page .zoneName {
    font-size: 18px;
    margin: 0;
}

#add-vehicle-page .editPbtIcon {
    float: right;
    color: #5C4E92;
}