#vehicle-page ion-header {
    background-color: #5C4E92;
}

#vehicle-page ion-title {
    text-align: center;
    color: white;
}

#vehicle-page ion-icon {
    color: white;
}

#vehicle-page .bottom-content {
    flex: 0 0 0%;
    margin: 0;
}

#vehicle-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#vehicle-page .image-center .center ion-img {
    text-align: center;
    width: 100pt;
}
#vehicle-page .image-center .center ion-label {
    display:block;
    text-align: center;
    font-size: 10pt;
    margin-top:10pt;
}

@media only screen and (min-width: 640px) {
    #vehicle-page .image-center .center ion-img {
        width: 200pt;
    }
    #vehicle-page .image-center .center ion-label {
        font-size: 14pt;
        margin-top:14pt;
    }
}

@media only screen and (min-width: 1024px) {
    #vehicle-page .image-center .center ion-img {
        width: 300pt;
    }
    #vehicle-page .image-center .center ion-label {
        font-size: 24pt;
        margin-top:24pt;
    }
}