 #start-page ion-img {
     height: 50px;
 }
 
 #start-page ion-header {
     background-color: #5C4E92;
 }
 
 #start-page ion-label {
     font-size: 13px;
 }
 
#start-page .divBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}