#list-zone-page ion-item {
    --inner-padding-end: 0;
    --background: transparent;
  }
  
  #list-zone-page ion-label {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 15px;
  }
  
  #list-zone-page ion-item h2 {
    font-weight: 600;
  }
  

  
  #list-zone-page ion-item ion-icon {
    font-size: 42px;
    margin-right: 8px;
  }
  
  #list-zone-page ion-item ion-note {
    font-size: 15px;
    margin-right: 12px;
    font-weight: normal;
  }
  
  #list-zone-page h1 {
    margin: 0;
    font-weight: bold;
    font-size: 22px;
  }


#list-zone-page ion-header {
    background-color: #5C4E92;
}

#list-zone-page ion-title {
    text-align: center;
    color: white;
}

#list-zone-page ion-icon {
    color: white;
}

#list-zone-page .bottom-content {
    flex: 0 0 0%;
    margin: 0;
}

#list-zone-page .image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#list-zone-page .image-jp {
    height: 100%;
    padding: 50pt;
}